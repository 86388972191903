import Status from './Status';

document.addEventListener('DOMContentLoaded', () => {
  const statusElement = document.querySelectorAll('.js-evolution');
  if (!statusElement.length) {
    return;
  }

  statusElement.forEach((element) => {
    const status = new Status(element);
    status.mount(element);
  })
});
