import 'mdn-polyfills/NodeList.prototype.forEach';
import L from "leaflet";
import 'leaflet.markercluster';


export default class Map {
  constructor(element) {
    this.element = element;
    this.lat = this.element.dataset.lat;
    this.log = this.element.dataset.log;
  }


  mount() {
    if (this.element.classList.contains("c-map--simple")) {
      var map = L.map(this.element, {
        keyboard: false,
        scrollWheelZoom: false
      }).setView([this.lat, this.log], 14);
    }else {
      var map = L.map(this.element, {
        keyboard: false,
        scrollWheelZoom: false,
      }).setView([this.lat, this.log], 10);
    }

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(map);

    const markerDefault = L.divIcon({
      html: `
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 58">
      <circle cx="24" cy="24" r="24" fill="currentColor"/>
      <path fill="currentColor" d="M18 47h11l-5.5 11L18 47Z"/>
      <path fill="#fff" d="M35 33.75H13c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h22c.41 0 .75.34.75.75s-.34.75-.75.75Z"/>
      <path fill="#fff" d="M31.781 33.76c-.41 0-.75-.34-.75-.75v-4.46c0-.41.34-.75.75-.75s.75.34.75.75v4.46c0 .41-.33.75-.75.75Z"/>
      <path fill="#fff" d="M31.8 29.3c-1.63 0-2.95-1.32-2.95-2.95v-2.27a2.949 2.949 0 1 1 5.9 0v2.27c0 1.63-1.32 2.95-2.95 2.95Zm0-6.66c-.8 0-1.45.65-1.45 1.45v2.27a1.451 1.451 0 0 0 2.9 0v-2.27c0-.8-.65-1.45-1.45-1.45ZM26.3 33.75c-.41 0-.75-.34-.75-.75V17.03c0-1.59-.67-2.27-2.23-2.27h-6.23c-1.57 0-2.25.68-2.25 2.27V33c0 .41-.34.75-.75.75s-.75-.34-.75-.75V17.03c0-2.43 1.33-3.77 3.75-3.77h6.23c2.41 0 3.73 1.34 3.73 3.77V33c0 .41-.34.75-.75.75Z"/>
      <path fill="#fff" d="M22.75 20H17.8c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h4.95c.41 0 .75.34.75.75s-.34.75-.75.75ZM22.75 23.75H17.8c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h4.95c.41 0 .75.34.75.75s-.34.75-.75.75ZM20.25 33.75c-.41 0-.75-.34-.75-.75v-3.75c0-.41.34-.75.75-.75s.75.34.75.75V33c0 .41-.34.75-.75.75Z"/>
    </svg><span class="u-hidden-visually">Ouvrir la prévisualisation du programme</span>`,
      className: "c-marker",
      iconSize: [48, 50],
      iconAnchor: [12, 40],
    });

    var markers = L.markerClusterGroup(
      {
        iconCreateFunction: function(cluster) {
          return L.divIcon({ html: '<b>' + cluster.getChildCount() + '</b>' });
        }
      }
    );

    if (this.element.classList.contains("c-map--simple")) {

      const marker = L.marker([
        this.lat,
        this.log
      ], {
        icon: markerDefault,
        keyboard: false,
      })
      markers.addLayer(marker)
    }

    else {

      let programms = document.querySelectorAll(".c-card");
      let arrayOfMarkers = [];

      programms.forEach((item, index) => {

        let newMarker = [item.getAttribute("data-lat") , item.getAttribute("data-lng")]
        arrayOfMarkers.push(newMarker)

        const marker = L.marker([
          item.getAttribute("data-lat"),
          item.getAttribute("data-lng"),
        ], {
          icon: markerDefault,
          customId: index,
          draggable: true,
          keyboard: false,
        }).on('click', onClick);


        markers.addLayer(marker);
      });

      function onClick(e) {

        e.target.options.icon.options.className == "c-marker c-marker--active";

        if(document.querySelector(".c-card--map")) {
          document.querySelector(".c-card--map").remove();
        }

        let map = document.querySelector(".js-map");
        let selectedCard = document.querySelector("[data-lat='" + e.target._latlng.lat + "']");

        // create "voir detail links"
        let cardLink = selectedCard.querySelector(".c-card__mainlink").href;
        let previewCardLink = '<div class="c-card__footer"><a href="'+ cardLink+'" class="c-button c-button--simple c-card__button"><span class="c-button__label">Voir le détails</span></a></div>';

        let cardHtml = selectedCard.innerHTML + previewCardLink;

        // create close button
        const previewCardClose = document.createElement("button");
        previewCardClose.innerHTML='<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18" width="18" height="18"><path fill="#000" fill-rule="evenodd" d="M3.83 14.17a.563.563 0 0 1 0-.795l9.546-9.546a.563.563 0 0 1 .795.796L4.625 14.17a.563.563 0 0 1-.795 0Z" clip-rule="evenodd"/><path fill="#000" fill-rule="evenodd" d="M3.83 3.83c.22-.22.575-.22.795 0l9.546 9.545a.562.562 0 1 1-.795.796L3.83 4.625a.563.563 0 0 1 0-.796Z" clip-rule="evenodd"/></svg><span class="u-hidden-visually">Fermer la prévisualisation du programme</span>';
        previewCardClose.className="c-card__close";

        // create carte
        const previewCard = document.createElement("div");
        previewCard.className="c-card c-card--map";
        previewCard.innerHTML=cardHtml;
        previewCard.appendChild(previewCardClose);
        map.appendChild(previewCard);

        // close carde case
        let buttonClose = document.querySelector(".c-card__close");
        buttonClose.addEventListener("click", function (e) {
          previewCard.remove();
        })


      }


      var bounds = new L.LatLngBounds(arrayOfMarkers);
      map.fitBounds(bounds);
    }



    map.addLayer(markers);

    //Disable Focus of Zoom Buttons, Copyright links etc.:
	 	var elements = document.querySelectorAll(".leaflet-control a");
	 	for (var i = 0; i < elements.length; ++i) {
	 	  elements[i].setAttribute("tabindex", "-1");
    }

    if (this.element.classList.contains("c-map--simple")) {
      if (window.matchMedia("(max-width: 620px)").matches) {
        let xsMapCenterLat = parseFloat(this.lat) - 0.002;
        let xsMapCenterLng = this.log;

        map.dragging.disable();
        map.touchZoom.disable();
        map.doubleClickZoom.disable();
        map.scrollWheelZoom.disable();
        map.boxZoom.disable();
        map.keyboard.disable();
        if (map.tap) map.tap.disable();
        document.getElementById('map').style.cursor='default';
        map.panTo(new L.LatLng(xsMapCenterLat, xsMapCenterLng));
      }
    }

  }

  unmount() {
  }
}






