import 'mdn-polyfills/NodeList.prototype.forEach';

export default class Stickymenu {
  constructor(element) {
    this.element = element;
    this.sections = document.querySelectorAll(".js-stickymenu-section");
    this.menuItems = document.querySelectorAll(".js-stickymenu-item");
  }

  mount() {
    let lastKnownScrollPosition = 0;
    let ticking = false;

    document.addEventListener("scroll", (event) => {
      lastKnownScrollPosition = window.scrollY;

      if (!ticking) {
        window.requestAnimationFrame(() => {
          updateScroll(lastKnownScrollPosition);
          ticking = false;
        });

        ticking = true;
      }
    });



    function updateScroll() {
      var current = "";

      var startPointElement = document.querySelector("body").getBoundingClientRect();
      let startPointTop = startPointElement.top;


      let sections = document.querySelectorAll(".js-stickymenu-section");
      let menuItems = document.querySelectorAll(".js-stickymenu-item");

      sections.forEach((section,index) => {
        var rect = section.getBoundingClientRect();
        let sectionTop = rect.top;
        if (document.documentElement.scrollTop >= sectionTop - startPointTop + 100 - window.innerHeight) {
          current = section.getAttribute("id"); }
      });

      menuItems.forEach((menuItem) => {
        menuItem.classList.remove("c-stickynav__item--active");
        if (menuItem.getAttribute('data-id') === current) {
          menuItem.classList.add("c-stickynav__item--active");
        }
      });
    }

  }

  unmount() {
  }
}
