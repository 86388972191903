import Share from './Share';

document.addEventListener('DOMContentLoaded', () => {
  const shareElements = document.querySelectorAll('.js-share');
  if (shareElements.length) {
    shareElements.forEach((shareElement) => {
      const share = new Share(shareElement);
      share.mount();
    });
  }
});
