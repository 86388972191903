/**
 * Name: Seemore
 * Description: Collapsing section
 */

import Component from "@lunaweb/vega-reactor-js/src/patterns/Component";

export default class Seemore extends Component {
  static settings = {
    i18n: {
      hideSection: 'Replier le contenu',
      showSection: 'Déplier le contenu',
    }
  };

  #control;
  #content;
  #isOpen;


  #handleControlClick;
  #handleResize;
  #handleToggle;

  constructor (element, options = {}) {
    super(element, {
      ...Seemore.settings,
      ...options
    });

    this.#control = this.element.querySelector('.js-seemore-control');
    this.#content = this.element.querySelector('.js-seemore-content');

    this.#handleControlClick = this._handleControlClick.bind(this);
    this.#handleResize = this._handleResize.bind(this);
  }

  _handleResize() {
    this.createTeaser();
  }

  _handleControlClick (e) {
    this.toggle();
  }

  toggle () {
    this.#isOpen ? this.close() : this.open();
  }

  close () {
    this.#content.setAttribute('mainContentIsHiden', '');
    this.#control.querySelector(".c-button__label").innerText=this.settings.i18n.showSection;
    this.#isOpen = false;
  }

  open () {
    this.#content.removeAttribute('mainContentIsHiden', '');
    this.#control.querySelector(".c-button__label").innerText=this.settings.i18n.hideSection;
    this.#isOpen = true;
  }

  mount () {

    this.createTeaser();
    window.addEventListener('resize', this.#handleResize);
    super.mount();
  }

  createTeaser() {
    this.#content.removeAttribute('mainContentIsHiden', '');
    var divHeight = this.#content.offsetHeight;
    if (window.matchMedia("(max-width: 620px)").matches) {
      var lines = divHeight / 21;
    } else {
      var lines = divHeight / 24;
    }

    if (lines > 4) {
      this.#content.setAttribute('mainContentIsHiden', '');
      this.#control.removeAttribute('aria-hidden', '');
      this.#isOpen = false;
      this.#control.addEventListener('click', this.#handleControlClick);
    } else {
      this.#control.setAttribute('aria-hidden', 'true');
      this.#isOpen = true;
      this.#control.removeEventListener('click', this.#handleControlClick);
    }
  }

  unmount () {

    super.unmount();
  }
}
