import 'mdn-polyfills/NodeList.prototype.forEach';

export default class Share {
  constructor(element) {
    this.element = element;
    this.button = this.element
    this.shareUrl = this.button.getAttribute("data-share");

    this.shareText= this.button.getAttribute("data-share-text");
    this.shareTextFallback = this.button.getAttribute("data-share-text-fallback");

    this.buttonIcon = this.button.querySelector(".js-share-icon");
    this.buttonLabel = this.button.querySelector(".js-share-label");

    this.svgColler = '<svg width="16" height="16" aria-hidden="true"><path fill="currentColor" d="M15.533 4.718a.747.747 0 0 0-1.058 0l-7.717 7.717L3.54 9.217a.748.748 0 0 0-1.057 1.058l3.75 3.75a.747.747 0 0 0 1.057 0l8.243-8.242a.747.747 0 0 0 0-1.058v-.007Z"/></svg>';
    this.svgDefault = '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18"><path fill="currentColor" d="M8.325 17.063h-3.15c-2.932 0-4.237-1.305-4.237-4.238v-3.15c0-2.933 1.305-4.238 4.237-4.238h3.15c2.933 0 4.238 1.305 4.238 4.238v3.15c0 2.933-1.306 4.238-4.238 4.238Zm-3.15-10.5c-2.325 0-3.112.787-3.112 3.112v3.15c0 2.325.787 3.113 3.112 3.113h3.15c2.325 0 3.113-.788 3.113-3.113v-3.15c0-2.325-.788-3.113-3.113-3.113h-3.15Z"/><path fill="currentColor" d="M12.825 12.563H12a.567.567 0 0 1-.563-.563V9.675c0-2.325-.787-3.113-3.112-3.113H6A.567.567 0 0 1 5.437 6v-.825c0-2.932 1.305-4.237 4.238-4.237h3.15c2.933 0 4.238 1.305 4.238 4.237v3.15c0 2.933-1.305 4.238-4.238 4.238Zm-.262-1.126h.262c2.325 0 3.113-.787 3.113-3.112v-3.15c0-2.325-.788-3.112-3.113-3.112h-3.15c-2.325 0-3.113.787-3.113 3.112v.263h1.763c2.933 0 4.238 1.304 4.238 4.237v1.762Z"/></svg>';
    this.svgShare = '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18"> <path fill="currentColor" fill-rule="evenodd" d="M14.25 11.25c-1.095 0-2.04.592-2.565 1.463l-5.16-2.58A3.02 3.02 0 0 0 6.75 9c0-.398-.082-.78-.225-1.133l5.16-2.58A2.993 2.993 0 0 0 14.25 6.75a3 3 0 1 0 0-6 3 3 0 0 0-3 3c0 .165.023.315.045.473l-5.393 2.7A2.996 2.996 0 0 0 3.75 6a3 3 0 1 0 0 6c.848 0 1.612-.352 2.152-.922l5.393 2.7c-.022.157-.045.307-.045.472a3 3 0 1 0 6 0 3 3 0 0 0-3-3Zm0-9.375a1.876 1.876 0 1 1-.001 3.751 1.876 1.876 0 0 1 .001-3.751Zm-10.5 9a1.876 1.876 0 1 1 .001-3.751 1.876 1.876 0 0 1-.001 3.751Zm10.5 5.25a1.876 1.876 0 1 1 .001-3.751 1.876 1.876 0 0 1-.001 3.751Z" clip-rule="evenodd"/></svg>';

    this.copy = this.copy.bind(this);

  }


  mount() {
    const share = e => {
      if (navigator.share) {
        navigator
          .share({
            url: this.shareUrl,
          })
          .then(() => console.log(""))
          .catch(error => console.log("error", error));

      }
    };

    if (navigator.share) {
      this.buttonIcon.innerHTML = this.svgShare;
      this.buttonLabel.innerHTML ="Partager " + this.shareText;
      this.button.addEventListener("click", share);
    } else if (window.isSecureContext && navigator.clipboard) {
      this.buttonIcon.innerHTML = this.svgDefault
      this.button.addEventListener("click", this.copy)
    } else {
      this.button.remove();
    }
  }

  copy() {
    this.svgDefult = this.buttonIcon;
    navigator.clipboard.writeText(this.shareUrl);
    this.buttonLabel.innerText="Lien copié";
    this.buttonIcon.innerHTML = this.svgColler;
    setTimeout(() => {
      this.buttonLabel.innerText="Copier le lien " + this.shareTextFallback;
      this.buttonIcon.innerHTML = this.svgDefault
    }, "2000")
  }

  unmount() {
  }
}
