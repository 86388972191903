import 'mdn-polyfills/NodeList.prototype.forEach';
import DataGouvSearch from '../dataGouv/DataGouv';
export default class Filter {
  constructor(element) {
    this.element = element;
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  mount() {
    const dataGouvSearchElement = document.getElementById('autocomplete-datagouv-search');

    if(dataGouvSearchElement) {
      this.dataGouvSearch = new DataGouvSearch(dataGouvSearchElement);
      this.dataGouvSearch.mount();
    }

    this.element.addEventListener('submit', this.handleSubmit);

    // display stickymenu pour accedere au filtre sur mobil

    let stickyNav = document.querySelectorAll(".c-filter-summary");
    if (stickyNav[0]){
      let filter = document.querySelectorAll(".js-filter");

      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          stickyNav[0].classList.toggle(
            'c-filter-summary--hidden',
            entry.intersectionRatio == 0
          )
        })
      }, { threshold: [0, 1] })

      filter.forEach(filterItem => observer.observe(filterItem))
    }
  }

  handleSubmit(e) {

    if (this.dataGouvSearch.menuOpen) {
      e.preventDefault();
    } else {
    }
  }
}
