
export default class Intro {
  constructor(element) {
    this.element = element;
    this.trigger = document.querySelector(".js-intro-trigger");
    this.letter = this.element.querySelector(".c-letter")
  }

  mount() {
    var windowHeight = window.innerHeight;
    let espaceTop= this.letter.getBoundingClientRect().top;
    let progress=0;
    let progressCss=0;

    let lastKnownScrollPosition = 0;
    let lastScrollWasInArea = false;
    let ticking = false;
    let sectioncount = 0;

    let imageHeight;
    if (window.matchMedia("(max-width: 620px)").matches) {
      imageHeight=(windowHeight*1);
    }else {
      imageHeight=windowHeight;
    }

    function updateScroll() {

      var containerTop =  document.querySelector(".js-intro-trigger").getBoundingClientRect().top;

      // p commence a grandir au demi du espace (p et viewport top) et ca arrete a grandir 100vh avant le fin du container
      let isInCalculatonArea = window.scrollY > (espaceTop*0.5) && containerTop > imageHeight
      let isBeforeArea = window.scrollY < (espaceTop*0.5);
      let isAfterArea = containerTop < imageHeight;

      // scroll speed intro text
      if (window.scrollY < espaceTop) {
        document.querySelector(".c-intro").style.transform = "translateY( -"+ (window.scrollY  * 0.2) +"px)";
      }

      if (isInCalculatonArea) {
        sectioncount= (window.scrollY - (espaceTop*0.5));
        progress = sectioncount/(windowHeight + (espaceTop*0.5));

        progressCss = (1+( progress * 5));
        document.documentElement.style.setProperty("--letterProgress", `${progressCss}`);
        document.documentElement.style.setProperty("--bgLetterProgress", `${((1) +( 0.1 * progress))}`);

        lastScrollWasInArea = true;
      } else {
        if (lastScrollWasInArea) {
          if(isBeforeArea) {
            progress=0;
          }
          if(isAfterArea) {
            progress=1;
          }
        }
        progressCss = (1+(progress * 5));
        document.documentElement.style.setProperty("--letterProgress", `${progressCss}`);

        lastScrollWasInArea = false;
      }
    }

    document.addEventListener("scroll", (event) => {
      lastKnownScrollPosition = window.scrollY;

      if (!ticking) {
        window.requestAnimationFrame(() => {
          updateScroll(lastKnownScrollPosition);
          ticking = false;
        });

        ticking = true;
      }
    });
  }

  updateProgress() {

  }
}
