import 'mdn-polyfills/NodeList.prototype.forEach';

export default class Viewport {
  constructor(element) {
    this.element = element;
  }

  mount() {
    this.element.classList.add("is-viewport");
  }

  unmount() {
    if(this.element.classList.contains("is-viewport")){
      this.element.classList.remove("is-viewport");

      const event = new Event('viewport:notIntersecting');
      document.dispatchEvent(event);
    }
  }
}
