import 'mdn-polyfills/NodeList.prototype.forEach';
import Viewport from './Viewport';

document.addEventListener('DOMContentLoaded', () => {

  const isReduced = window.matchMedia(`(prefers-reduced-motion: reduce)`) === true || window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;

  if (!!isReduced) {
  } else {
    const observedElements = document.querySelectorAll('.is-observed');
    if (!observedElements.length) {
      return;
    }
    var halfHauter = window.innerHeight;

    if(!!window.IntersectionObserver){
      let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if(entry.isIntersecting){
            const observedElement = new Viewport(entry.target);
            observedElement.mount();
          } else{
            const observedElement = new Viewport(entry.target);
            observedElement.unmount();
          }
        });
      }, {rootMargin: "0px 0px "+ halfHauter+"px 0px"});
      document.querySelectorAll('.is-observed').forEach(observedElement => {
        observer.observe(observedElement) });
    }
  }
});
