import 'mdn-polyfills/NodeList.prototype.forEach';

export default class Summary {
  constructor(element) {
    this.element = element;
    this.progressBarBox = element;
    this.progressBar = this.progressBarBox.querySelector(".c-summary__bar__progress");
    this.section = document.querySelector(".o-blocks");
    this.body = document.body;
    this.hasScrolled = true;

    this.handleScroll = this.handleScroll.bind(this);
    this.scrollProgressBar = this.scrollProgressBar.bind(this);


  }

  mount() {
    window.addEventListener('scroll', this.handleScroll);
    const loop = () => {
      if (this.hasScrolled) {
        this.scrollProgressBar();
        this.hasScrolled = false;
      }

      requestAnimationFrame(loop);
    }

    loop();
  }

  handleScroll() {
    this.hasScrolled = true;
  }

  scrollProgressBar(){
    let scrollDistance = -(this.section.getBoundingClientRect().top);
    let progressPercentage =
        (scrollDistance /
            (this.section.getBoundingClientRect().height -
                document.documentElement.clientHeight)) * 100;

    let val = Math.floor(progressPercentage);

    if (val < 0) {
      this.progressBar.style.height = '0%';
    } else if (val < 100) {
      this.progressBar.style.height = val + '%';
    }else {
      this.progressBar.style.height = '100%';
    }
  };
}
