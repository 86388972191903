import Intro from './Intro';

document.addEventListener('DOMContentLoaded', () => {

  const introElement = document.querySelectorAll('.js-sticky-intro');
  if (!introElement.length) {
    return;
  }

  introElement.forEach((element) => {
    const intro = new Intro(element);
    intro.mount(element);
  })
});
