
import GLightbox from 'GLightbox';

export default class Glightbox {
  constructor(element) {
    this.element = element;
  }

  mount() {
    const lightbox = GLightbox({
      'selector': '.glightbox'
    });

    this.element.addEventListener('click', function(){
      lightbox.open();
    })
  }
}
