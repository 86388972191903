import "./front/components/Header/lifecycle";
import './front/components/Breadcrumbs/lifecycle';
import './front/components/Details/lifecycle';
import './front/components/Dropdown/lifecycle';
import './front/components/Seemore/lifecycle';
import './front/components/Share/lifecycle';
import './front/components/Glightbox/lifecycle';
import './front/components/Glightboxbutton/lifecycle';
import './front/components/Slider/lifecycle';
import './front/components/Map/lifecycle';
import './front/components/Summary/lifecycle';
import './front/components/Intro/lifecycle';
import './front/components/filter/lifecycle';
import "./front/components/Viewport/lifecycle";
import "./front/components/Status/lifecycle";
import "./front/components/Stickymenu/lifecycle";

let scrollbarWidth = window.innerWidth - document.body.clientWidth;
  document.documentElement.style.setProperty("--scrollbarWidth", `${scrollbarWidth}px`);

window.addEventListener('resize', function(event) {
  let scrollbarWidth = window.innerWidth - document.body.clientWidth;
  document.documentElement.style.setProperty("--scrollbarWidth", `${scrollbarWidth}px`);
}, true);
