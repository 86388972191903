import Glightboxbutton from './Glightboxbutton';

document.addEventListener('DOMContentLoaded', () => {

  const glightboxElement = document.querySelectorAll('.js-glightbox__button');
  if (!glightboxElement.length) {
    return;
  }

  glightboxElement.forEach((element) => {
    const glightboxE = new Glightboxbutton(element);
    glightboxE.mount(element);
  })
});
