export default class Status {
  constructor(element) {
    this.element = element;
    this.items = this.element.querySelectorAll(".c-evolution__item");
  }

  mount() {
    let activeElementOffset;
    let activeElementWidth;
    let scrollBoxWidth = this.element.offsetWidth;
    for (let i = 0; i < this.items.length; i++) {
      const element = this.items[i];
      if (element.getAttribute('aria-hidden') === 'false') {
        activeElementOffset = element.offsetLeft;
        activeElementWidth = element.offsetWidth;
      }
    }

    this.element.scrollLeft = activeElementOffset - (scrollBoxWidth*0.5) + (activeElementWidth*0.5);
  }
}
