
import GLightbox from 'GLightbox';

export default class Glightbox {
  constructor(element) {
    this.element = element;
    this.button = document.querySelector(".js-glightbox__button");
  }

  mount() {
    const lightbox = GLightbox({
      'selector': '.glightbox'
    });
  }
}
