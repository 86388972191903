
export default class DataGouvSearch {

  constructor(element, props = {}) {
    this.element = element;
    this.input = this.element.querySelector('.js-search-input');
    this.geoInput = document.querySelector('.js-location-input');
    this.geoLatitude = document.querySelector('.js-latitude');
    this.geoLongitude = document.querySelector('.js-longitude');

    this.output = this.element.querySelector('.js-search-output');
    this.datalist = document.getElementById(this.input.getAttribute('list'));
    this.menu = null;
    this.live = null;
    this.tmp = this.input.value;
    this.menuOpen = false;

    this.handleInputFocusIn = this.handleInputFocusIn.bind(this);
    this.handleInputBlur = this.handleInputBlur.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
  }

  handleChange(e = null) {
    if(this.datalist) {
      const item = this.datalist.querySelector(`option[value="${ this.input.value }"]`);
      this.selected = {
        label: item.dataset.label,
        lat: item.dataset.lat,
        lng: item.dataset.lng
      };
    }

    if(this.selected) {
      this.geoInput.value = this.selected.label;
      this.geoLatitude.value= this.selected.lat;
      this.geoLongitude.value= this.selected.lng;

    } else {
    }
  }

  handleKeyDown(e) {
    if (e.key && e.key !== 'Enter' && e.key !== 'ArrowUp' && e.key !== 'ArrowDown' && e.key !== 'Escape') {
      if(e.key === 'Tab') {
        return;
      }

      if(e.key === ' ' && this.selected) {
        e.preventDefault();
        this.controlMenu(e.key);
        return;
      }

      if (this.datalist) {
        this.clearDatalist();
      }

      if (this.menu) {
        this.selected = null;
        this.clearMenu();
      }

      if (this.input.value !== '' && this.input.value !== this.tmp && this.input.value.length >= 3) {
        this.getPlaces();
      }

      this.tmp = this.input.value;
    } else {
      if (this.menu) {
        e.preventDefault();

        if(this.results) {
          this.controlMenu(e.key);
        }
      }
    }
  }

  handleInputBlur(e) {
    this.hasFocus = false;
    if(this.menu) {
      this.closeMenu();
    }
  }

  handleInputFocusIn(e) {
    this.openMenu();
    this.hasFocus = true;
  }

  handleMenuItemClick(e) {
    e.preventDefault();
    this.selected = {
      label: e.target.dataset.label,
      lat: e.target.dataset.lat,
      lng: e.target.dataset.lng,
    };
    this.validateSelection();
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  controlMenu(key) {
    switch(key) {
      case 'ArrowUp':
        this.navigateMenu(-1);
        break;
      case 'ArrowDown':
        this.navigateMenu(1);
        break;
      case 'Escape':
        this.closeMenu();
        break;
      case 'Enter':
        this.validateSelection();
        break;
      case ' ':
        this.validateSelection();
        break;
      default:
        break;
    }
  }

  validateSelection() {
    if(this.selected) {
      this.input.value = this.selected.label;
      this.handleChange();
      this.clearMenu();
      this.menuOpen = false;
    }
  }

  getLiveText() {
    let text = `${this.results.length} résultat${ this.results.length > 1 ? 's' : null } disponible${ this.results.length > 1 ? 's' : null }.`;

    if(this.selected) {
      text += ` ${this.selected.label} ${this.index} sur ${this.results.length} est selectionné.`
    }

    return text;
  }

  navigateMenu(delta) {
    let i = this.index ? this.index : 0;

    i = this.index + delta;
    i = Math.min(i, this.results.length);
    i = Math.max(i, 0);

    this.index = i;

    this.menu.querySelectorAll('[aria-posinset]').forEach((item) => {
      const state = i == parseInt(item.getAttribute('aria-posinset'));
      item.setAttribute('aria-selected', `${ state }`);

      if(state) {
        this.selected = {
          label: item.dataset.label,
          lat: item.dataset.lat,
          lng: item.dataset.lng,
        };
      }
    });

    this.live.innerHTML = this.getLiveText();
  }

  getPlaces() {
    let query = encodeURI(this.input.value);
    const now = new Date();
    const timestamp = now.getTime();
    this.lastRequest = timestamp;
    this.menu.classList.add('is-loading');

    fetch(`https://api-adresse.data.gouv.fr/search/?q=${query}&type=municipality`, {
      method: 'GET',
      cache: 'default',
    })
      .then((response) => {
        if (!response.ok || response.status !== 200) {
          throw Error(response.statusText);
        }

        return response.json();
      })
      .then((json) => {
        if(timestamp == this.lastRequest) {
          if(json.features?.length > 0) {
            if(this.datalist) {
              this.datalist.innerHTML = this.generateDatalist(json.features);
            } else {
              this.results = json.features;
              this.index = null;
              this.menu.classList.remove('is-loading');
              this.menu.innerHTML = this.generateMenu(json.features);
              this.live.innerHTML = this.getLiveText();
              this.items = this.menu.querySelectorAll('li[aria-selected]');

              if(this.items && this.items.length) {
                this.items.forEach((item) => {
                  item.addEventListener('mousedown', this.handleMenuItemClick);
                });
              }
            }
          }
        }
      });
  }

  clearDatalist() {
    this.datalist.innerHTML = '';
  }

  clearMenu() {
    if(this.items && this.items.length) {
      this.items.forEach((item) => {
        item.removeEventListener('mousedown', this.handleMenuItemClick);
      });
    }

    this.items = null;
    this.menu.innerHTML = '';
  }

  closeMenu() {
    this.menu.setAttribute('hidden', '');
  }

  openMenu() {
    this.menu.removeAttribute('hidden');
    this.menuOpen = true;
  }

  generateDatalist(items) {
    let html = ``;

    items.forEach((item) => {
      html += `<option value="${ item.properties.label }" data-lat="${ item.geometry.coordinates[1] }" data-lng="${ item.geometry.coordinates[0] }"></option>`;
    });

    return html;
  }

  generateMenu(items) {
    let html = `<ul class="c-autocomplete__items c-dropdown__items" role="listbox">`;

    items.forEach((item, index) => {
      html += `<li class="c-autocomplete__item c-dropdown__item c-dropdown__link" role="option" aria-posinset="${ index + 1 }" aria-setsize="${ items.length }" aria-selected="false" tabindex="-1" data-label="${ item.properties.label } (${ item.properties.postcode.slice(0, 2)})" data-lat="${ item.geometry.coordinates[1] }" data-lng="${ item.geometry.coordinates[0] }">${ item.properties.label } (${ item.properties.postcode.slice(0, 2)})</li>`;
    });

    html == `</ul>`;

    return html;
  }

  mount() {
    if(!this.datalist) {
      this.input.setAttribute('role', 'combobox');
      this.input.setAttribute('autocomplete', 'off');
      this.input.setAttribute('aria-autocomplete', 'list');
      this.input.setAttribute('aria-expanded', 'false');
      this.input.setAttribute('aria-controls', `${ this.element.id }-menu`);

      const menu = document.createElement('div');
      menu.classList.add('c-autocomplete__menu');
      menu.setAttribute('hidden', '');
      menu.id = `${ this.element.id }-menu`;

      this.menu = menu;
      this.input.parentNode.append(this.menu);

      const live = document.createElement('div');
      live.classList.add('c-autocomplete__live');
      live.id = `${ this.element.id }-live`;
      live.setAttribute('role', 'status');
      live.setAttribute('aria-atomic', 'true');
      live.setAttribute('aria-live', 'polite');

      this.live = live;
      this.element.prepend(this.live);
    }

    this.input.addEventListener('keyup', this.handleKeyDown);
    this.input.addEventListener('change', this.handleChange);
    this.input.addEventListener('blur', this.handleInputBlur);
    this.input.addEventListener('focusin', this.handleInputFocusIn);
    this.element.addEventListener('submit', this.handleSubmit);
  }

  unmount() {
    if(!this.datalist) {
      this.input.removeAttribute('role');
      this.input.removeAttribute('autocomplete');
      this.input.removeAttribute('aria-autocomplete');
      this.input.removeAttribute('aria-expanded');
      this.input.removeAttribute('aria-controls');

      this.menu.remove();
      this.menu = null;

      this.live.remove();
      this.live = null;
    }

    this.input.removeEventListener('keypress', this.handleKeyDown);
    this.input.removeEventListener('change', this.handleChange);
  }
}
