import Map from './Map';

document.addEventListener('DOMContentLoaded', () => {
  const mapElements = document.querySelectorAll('.js-map');
  if (mapElements.length) {
    mapElements.forEach((mapElement) => {
      const map = new Map(mapElement);
      map.mount();
    });
  }
});
