import Seemore from './Seemore';

document.addEventListener('DOMContentLoaded', () => {
  const seemoreElements = document.querySelectorAll('.js-seemore');
  if (!seemoreElements.length) {
    return;
  }
  seemoreElements.forEach((element) => {
    const seemoreElement = new Seemore(element, {
      hasAdditionalControl: true,
    });
    seemoreElement.mount();
  });
});
